import React, { Component } from 'react';
import Utils from 'shared/utils';
import { generateGameListData } from 'shared/components/GamePage/utils';
import GameTile from '../GameTile';
import CustomScrollBar from '../CustomScrollBar';
import { MainContainer, getScrollBarConfig } from './styles';
import './scrollBarStyles.scss';
import Banner from '../Banner/Banner';
import { checkIfBannersEnabled } from 'shared/components/GamePage/utils';

export default class Home extends Component {
  state = {
    scrolling: false,
  };
  gameTileRef = React.createRef();
  gameTileWidth = 0;

  measureGameTileWidth = () => {
    if (this.gameTileRef.current) {
      this.gameTileWidth = this.gameTileRef.current.offsetWidth;
    }
  };

  componentDidUpdate() {
    this.measureGameTileWidth();
  }

  render() {
    const {
      pagedGames,
      pageNumber,
      navigation,
      ftueStep,
      gameIndex,
      hasPromotions,
      lobbyBanners,
      lobbyPromotions,
      trigger,
    } = this.props;
    const { gameList, totalGamesCount } = generateGameListData(pagedGames, pageNumber, ftueStep);
    const lobbyPromotionsLength = lobbyPromotions?.length;

    const bannersExist = lobbyBanners?.length > 0;
    const showBanner = checkIfBannersEnabled(bannersExist);

    return (
      <CustomScrollBar
        onScrollCallback={() => this.setState({ scrolling: !this.state.scrolling })}
        {...getScrollBarConfig(ftueStep, gameIndex, hasPromotions, totalGamesCount, showBanner, lobbyPromotionsLength)}
      >
        <MainContainer>
          {showBanner && (
            <Banner navigation={navigation} lobbyBanners={lobbyBanners} scrolling={this.state.scrolling} />
          )}
          {gameList.map((panelList, indexPaged) => {
            return (
              <div className="live-panel-grid" key={indexPaged}>
                {!Utils.isEmpty(panelList) &&
                  panelList.map((item, index) => {
                    return (
                      item && (
                        <GameTile
                          gameTileRef={this.gameTileRef}
                          highlightedForOnboarding={index === 0 && indexPaged === 0}
                          itemData={item}
                          key={index}
                          navigation={navigation}
                          trigger={trigger}
                        />
                      )
                    );
                  })}
              </div>
            );
          })}
        </MainContainer>
      </CustomScrollBar>
    );
  }
}

