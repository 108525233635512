import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { purchaseItemIds } from './types';
import { PLATFORM } from '../../../constants';
import Utils from '../../../utils';
import { PurchaseTypes } from '../../../utils/routeParamConstants';
export var packageGenerator = function packageGenerator(props) {
  var coinItems = props.coinItems,
    packageId = props.packageId,
    vipItems = props.vipItems,
    offerDetail = props.offerDetail,
    outOfCoinsPurchase = props.outOfCoinsPurchase,
    outOfCoins = props.outOfCoins,
    ltoOffer = props.ltoOffer,
    ltoPurchase = props.ltoPurchase,
    purchaseType = props.purchaseType;
  var isPlatformMobile = Utils.getPlatform() !== 'web';
  var selectedPack = {};
  switch (purchaseType) {
    case PurchaseTypes.CURRENCY:
      if (outOfCoinsPurchase) {
        selectedPack = _objectSpread({}, outOfCoins);
      } else if (ltoPurchase) {
        selectedPack = _objectSpread({}, ltoOffer);
      } else if (offerDetail) {
        selectedPack = {
          AMOUNT: offerDetail.coins,
          PRICE: offerDetail.price,
          CURRENCY_ITEM_ID: offerDetail.cpil_id,
          OFFER_ID: offerDetail.message_id
        };
      } else {
        selectedPack = deepCopyObjectWithoutRef(coinItems).find(function (item) {
          return item[isPlatformMobile ? purchaseItemIds.CURRENCY_MOBILE_ITEM_SKU : purchaseItemIds.CURRENCY_WEB_ITEM_ID] === packageId;
        }) || {};
      }
      break;
    case PurchaseTypes.VIP:
    case PurchaseTypes.CONSUMABLE:
      selectedPack = deepCopyObjectWithoutRef(vipItems).find(function (item) {
        return item[isPlatformMobile ? purchaseItemIds.VIP_MOBILE_ITEM_SKU : purchaseItemIds.VIP_WEB_ITEM_ID] === packageId;
      }) || {};
      break;
    default:
      console.error('Invalid pruchaseType.');
  }
  if (selectedPack) selectedPack.purchaseType = purchaseType;
  return selectedPack || {};
};
export var getSKUBasedOnPlatform = function getSKUBasedOnPlatform(itemPackage) {
  return _defineProperty(_defineProperty(_defineProperty({}, PLATFORM.IOS, itemPackage === null || itemPackage === void 0 ? void 0 : itemPackage.MOBILE_PRODUCT_IAP_ID_IOS), PLATFORM.ANDROID, itemPackage === null || itemPackage === void 0 ? void 0 : itemPackage.MOBILE_PRODUCT_IAP_ID_ANDROID), PLATFORM.WEB, itemPackage === null || itemPackage === void 0 ? void 0 : itemPackage.CURRENCY_ITEM_ID);
};
export var sendDataToCAMS = function sendDataToCAMS(args, formUrl) {
  var billing = args.billing;
  var expiryMonth = billing.expiryMonth.toString().length < 2 ? "0".concat(billing.expiryMonth) : billing.expiryMonth;
  var newLoc = "".concat(formUrl, "?billing-cc-number=").concat(billing.cc, "&billing-cvv=").concat(billing.cvv, "&billing-cc-exp=").concat(expiryMonth).concat(billing.expiryYear);
  window.location = newLoc;
  return true;
};
var deepCopyObjectWithoutRef = function deepCopyObjectWithoutRef(data) {
  return JSON.parse(JSON.stringify(data));
};