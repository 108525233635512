import React, { Component } from 'react';
import styled from 'styled-components';
import SGenericMessage from 'shared/screens/GenericMessage';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ModalHeader from './components/ModalHeader';
import {
  headerTitleWrapperStyle,
  headerContainerStyle,
  titleStyle,
  ModalContentWrapper,
  ButtonWrapper,
  styles,
} from './components/styledComponents';
import Button from '../../components/Button/Button';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
export default class GenericMessage extends Component {
  render() {
    return (
      <SGenericMessage
        {...this.props}
        render={
          /**
           *  @param {Object} args
           *  @param {String[]} args.apiRootList
           */
          args => {
            const { message, title, centerImage, buyNowButton, dividerImage } = args;
            return (
              <ModalContentWrapper>
                <ModalHeader
                  titleStyle={titleStyle}
                  headerTitleWrapperStyle={headerTitleWrapperStyle}
                  containerStyle={headerContainerStyle}
                  title={title}
                  showDivider={dividerImage}
                />
                {centerImage && <MessageImage src={centerImage.src} alt="Modal" />}
                <GenericMessageWrapper>{message}</GenericMessageWrapper>
                {buyNowButton ? (
                  <Button
                    imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                    imageStyle={styles.buttonImage}
                    containerStyle={styles.buyNowButtonContainer}
                    onClick={this.props.dismissModal}
                    label={getText(TEXT_KEY.BUY_NOW)}
                  />
                ) : (
                  <ButtonWrapper>
                    <Button
                      imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                      imageStyle={styles.okButtonImage}
                      containerStyle={styles.buyNowButtonContainer}
                      onClick={this.props.dismissModal}
                      label={getText(TEXT_KEY.OK)}
                    />
                  </ButtonWrapper>
                )}
              </ModalContentWrapper>
            );
          }
        }
      />
    );
  }
}

const GenericMessageWrapper = styled.div`
  text-align: center;
  padding: 0 1rem;
`;

const MessageImage = styled.img`
  height: 50%;
`;
