import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useLogin from 'shared/screens/Login/useLogin';
import ThemeContext from 'shared/context/ThemeContext';
import CustomScrollBar from '../../components/CustomScrollBar';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import LoginButton from './components/LoginButton';
import { Container, Status } from './styles';
import FormInputs from './components/FormInputs';

const Login = ({ navigation }) => {
  const { formikValidation, closeModal, openForgotPassword, isLoading, onChangeHandler } = useLogin(navigation);

  const { values, errors, status, handleSubmit, setFieldValue, handleBlur } = formikValidation;

  const themeContext = useContext(ThemeContext);

  return (
    <TermsModalWrapper
      isVisible={true}
      closeModal={closeModal}
      title={getText(TEXT_KEY.LOGIN)}
      zIndex={101}
      modalContainerStyle={themeContext.Login.ModalContainer}
      headerStyle={themeContext.Login.HeaderStyle}
      bodyStyle={themeContext.Login.BodyStyle}
    >
      <CustomScrollBar>
        <Container>
          <FormInputs
            values={values}
            errors={errors}
            onChangeHandler={onChangeHandler}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            openForgotPassword={openForgotPassword}
            loginStyles={themeContext.Login}
          />
          {status && <Status>{status}</Status>}

          <LoginButton
            isValid={!isLoading}
            disabled={isLoading}
            textStroke={BUTTON_STROKES.PRIMARY}
            onClick={handleSubmit}
            label={getText(TEXT_KEY.LOGIN)}
            textStyle={themeContext.Login.LoginButtonText}
          />
        </Container>
      </CustomScrollBar>
    </TermsModalWrapper>
  );
};

export default Login;

