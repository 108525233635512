import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { setLTOOffer, setOutOfCoinOfferResponse, setTimedBonus } from '../../slices/consumables/consumables';
import { updateUnreadMessageCount } from '../../actions/notification';
import { setSaleAvailable } from '../../actions/shopList';
import { setLobbyBackgroundPromo } from '../../actions/promotions';
import { setXpData } from '../../slices/xp/xp';
import Utils from '../../../utils';
import TimeStampManager from '../../../utils/TimestampManager';
import transformObjectResponseKeys from '../../../utils/transformObjectResponseKeys';
import transformObjectArrayResponse from '../../../utils/transformObjectArrayResponse';
import { FavouriteResponseKeys, InitAppResponseKeys, LTOPackageItemKeys, OOCPackageItemKeys, UpdateAppEventResponseKeys, XPResponseKeys } from '../../../api/Constants';
import { promoListHandler } from '../../../utils/PromotionAction';
export var postInitappAdapter = function postInitappAdapter(data) {
  var raw = transformObjectResponseKeys(data[0], InitAppResponseKeys);
  var coinBalance = data[3][0];
  var loyaltyPoints = data[3][2];
  raw.PROMOTIONS = transformObjectArrayResponse(raw.PROMOTIONS);
  raw.PROMOTIONS_SECOND = transformObjectArrayResponse(raw.PROMOTIONS_SECOND);
  raw.PROMOTIONS_THIRD = transformObjectArrayResponse(raw.PROMOTIONS_THIRD);
  raw.WELCOME = transformObjectArrayResponse(raw.WELCOME);
  if (raw.XP || raw.XP === 0) raw.XP = transformObjectResponseKeys(raw.XP, XPResponseKeys);
  if (raw.FAVOURITE) raw.FAVOURITE = transformObjectResponseKeys(raw.FAVOURITE, FavouriteResponseKeys);
  if (raw.XP) {
    raw.XP.CURRENT_XP = parseInt(raw.XP.CURRENT_XP);
    raw.XP.XP_BET_MULTIPLIER = parseFloat(raw.XP.XP_BET_MULTIPLIER);
    raw.XP.XP_PER_SPIN = parseInt(raw.XP.XP_PER_SPIN);
  }
  if (raw.SETTINGS) {
    raw.SETTINGS.kyocInfo = {
      iskyocdone: raw.SETTINGS.kyocInfo.iskyocdone === 'yes',
      kyocresult: raw.SETTINGS.kyocInfo.kyocresult,
      info: raw.SETTINGS.kyocInfo.info
    };
    raw.SETTINGS.soundEffects = raw.SETTINGS.soundEffects === 'true';
    raw.SETTINGS.music = raw.SETTINGS.music === 'true';
  }
  raw.TUTORIAL = raw.TUTORIAL === 'yes';
  return _objectSpread(_objectSpread({}, raw), {}, {
    coinBalance: coinBalance,
    loyaltyPoints: loyaltyPoints
  });
};
export var handleUpdateAppEventsData = function handleUpdateAppEventsData(callResponse, dispatch, getState) {
  if (!callResponse) return;
  callResponse = transformObjectResponseKeys(callResponse[0], UpdateAppEventResponseKeys);
  checkLTO(callResponse, dispatch, getState);
  checkMessages(callResponse, dispatch);
  checkOutOfCoins(callResponse, dispatch);
  checkPromotions(callResponse.PROMOTIONS, callResponse.PROMOTIONS_SECOND, callResponse.PROMOTIONS_THIRD);
  checkTimedBonus(callResponse, dispatch);
  checkSale(callResponse, dispatch);
  checkXpMultiplier(callResponse, dispatch);
  checkLobbyBackgroundPromo(callResponse, dispatch);
};
var checkLTO = function checkLTO(callResponse, dispatch, getState) {
  var ltoCallBackHandler = function ltoCallBackHandler(getState, dispatch, setLTOOffer) {
    return function () {
      var isLToPurchase = function isLToPurchase() {
        return getState().prePurchase.ltoPurchase;
      };
      if (!isLToPurchase() || Utils.getPlatform() === 'web') {
        dispatch(setLTOOffer(null));
      }
    };
  };
  if (callResponse.LIMITED_TIME_OFFER) {
    var ltoResponse = transformObjectResponseKeys(callResponse.LIMITED_TIME_OFFER, LTOPackageItemKeys);
    var LtoObject = _objectSpread(_objectSpread({}, ltoResponse), {}, {
      OFFER_END_TIME: ltoResponse.TIME_LEFT_IN_SEC * 1000 + Date.now()
    });
    dispatch(setLTOOffer(LtoObject));
    TimeStampManager.register(ltoResponse.TIME_LEFT_IN_SEC * 1000, ltoCallBackHandler(getState, dispatch, setLTOOffer));
  }
};
var checkMessages = function checkMessages(callResponse, dispatch) {
  if (callResponse.MESSAGES && callResponse.MESSAGES.messages) {
    var messagesFromResponse = transformObjectArrayResponse(callResponse.MESSAGES.messages);
    if (messagesFromResponse.length > 0) {
      var msgcount = messagesFromResponse.filter(function (msg) {
        return !Utils.isEmpty(msg) && msg.message_read !== 'yes';
      }).length;
      dispatch(updateUnreadMessageCount(msgcount));
    }
  }
};
var checkOutOfCoins = function checkOutOfCoins(callResponse, dispatch) {
  var outOfCoinsResponse = transformObjectResponseKeys(callResponse.OUT_OF_COINS, OOCPackageItemKeys);
  dispatch(setOutOfCoinOfferResponse(outOfCoinsResponse));
};
var checkPromotions = function checkPromotions(slide_one, slide_two, slide_three) {
  return promoListHandler({
    first_slide: transformObjectArrayResponse(slide_one),
    second_slide: transformObjectArrayResponse(slide_two),
    third_slide: transformObjectArrayResponse(slide_three)
  });
};
var checkTimedBonus = function checkTimedBonus(callResponse, dispatch) {
  var timedBonus = _objectSpread(_objectSpread({}, callResponse.TIMED_BONUS), {}, {
    timeLeft: Date.now() + callResponse.TIMED_BONUS.timeLeft * 1000
  });
  dispatch(setTimedBonus(timedBonus));
};
var checkSale = function checkSale(callResponse, dispatch) {
  return dispatch(setSaleAvailable(callResponse.SALE));
};
var checkXpMultiplier = function checkXpMultiplier(callResponse, dispatch) {
  return dispatch(setXpData({
    xpBetMultiplier: callResponse.XP_BOOST_MULTIPLIER
  }));
};
var checkLobbyBackgroundPromo = function checkLobbyBackgroundPromo(callResponse, dispatch) {
  return dispatch(setLobbyBackgroundPromo(callResponse.LOBBY_BACKGROUND));
};