import React from 'react';
import useSportGames from 'shared/screens/SportGames/useSportGames';
import GamePage from '../../components/GamePage';
import TimeRemaining from '../../components/TimeRemaining/TimeRemaining';
import { GameListWrapper } from '../Home/styledComponents';
import AccessDenied from '../../components/AccessDenied/AccessDenied';
import { GAME_TRIGGERS } from 'shared/constants';

const SportGames = ({ navigation }) => {
  const { hasPromotions, hasGames, hasSportsRoomVip, timeRemaining, canEnterRoom, isAccountLinked, ...args } =
    useSportGames();

  return (
    <div className="roomPage">
      <div className="roomContent">
        {(!canEnterRoom && hasSportsRoomVip) || !isAccountLinked ? (
          <AccessDenied canEnterRoom={canEnterRoom} />
        ) : (
          <>
            {hasSportsRoomVip && timeRemaining > 0 && <TimeRemaining timeRemaining={timeRemaining} />}
            {hasGames && (
              <GameListWrapper banner={hasPromotions}>
                <div className={'GameListContainer'}>
                  <GamePage
                    {...args}
                    navigation={navigation}
                    hasPromotions={hasPromotions}
                    trigger={GAME_TRIGGERS.SPORTS}
                  />
                </div>
              </GameListWrapper>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SportGames;

