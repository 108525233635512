import { handleTooltip } from './tooltipCommands';
export var handleLinkAccountTooltip = function handleLinkAccountTooltip(actionCommand, props) {
  var text = actionCommand.tooltip.text;
  var details = {
    title: text,
    animationState: 'AnimationFadeIn',
    style: {
      top: '12vh',
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: window.innerWidth >= 1920 ? '69%' : window.innerWidth >= 1700 ? '72vw' : '73vw'
    },
    displayTextStyle: {
      fontSize: '17px',
      fontStyle: 'italic',
      flex: 1,
      textAlign: 'center',
      margin: '5px'
    },
    arrowStyle: {
      left: 70
    }
  };
  handleTooltip(details, props, actionCommand);
};
export var handleRewardCenterTooltip = function handleRewardCenterTooltip(actionCommand, props) {
  var text = actionCommand.tooltip.text;
  var details = {
    title: text,
    animationState: 'AnimationFadeIn',
    style: {
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '400px',
      left: 'calc(50% - 200px)',
      top: 'calc(100% - 280px)'
    },
    displayTextStyle: {
      fontSize: '17px',
      fontStyle: 'italic',
      flex: 1,
      textAlign: 'center',
      margin: '5px'
    },
    bottom: true
  };
  handleTooltip(details, props, actionCommand);
};
var getDetailEventsTooltip = function getDetailEventsTooltip(title) {
  return {
    title: title,
    animationState: 'AnimationFadeIn',
    style: {
      zIndex: '9999',
      fontWeight: '600',
      top: '10vh',
      left: '1vw',
      width: '23vw',
      height: '13vh'
    },
    displayTextStyle: {
      fontSize: '15px',
      fontStyle: 'italic',
      flex: 1
    },
    showArrow: false
  };
};
export var handleEventsTooltip = function handleEventsTooltip(actionCommand, props) {
  var details = getDetailEventsTooltip(actionCommand.tooltip.text);
  handleTooltip(details, props, actionCommand);
};