// core-js includes polyfills to get the app working on Internet Explorer
import 'core-js';
// whatwg-fetch is a window.fetch polyfill needed for fetch to work in older verisons of Internet Explorer
import 'whatwg-fetch';
import React, { useEffect, useRef } from 'react';
import './index.css';
import App from './App';
import MobileApp from './MobileApp';
import OnboardingLayer from './components/OnboardingLayer';
import MissionsTutorialLayer from './components/MissionsTutorialLayer';
import AppInit from './components/AppInit';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { Provider } from 'shared/node_modules/react-redux';
import { PersistGate } from 'shared/node_modules/redux-persist/lib/integration/react';
import { persistor, store } from 'shared/state/store';
import { setIsConnected } from 'shared/state/actions/device';
import { setAdBlocker } from 'shared/state/actions/apiRoot';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from './components/ErrorBoundary';
import ThemeContext from 'shared/context/ThemeContext';
import { themes } from 'shared/context/Themes';
import { NotificationProvider } from './context/NotificationProvider';
import { handleHtmlScripts } from './prependScripts';
import LoadingLayer from './components/Loading/Loading';

const Redux = () => {
  const willMount = useRef(true);

  const checkToLoadScripts = () => {
    if (willMount.current) {
      handleHtmlScripts();
      detectAdBlock();
    }
    willMount.current = false;
  };

  checkToLoadScripts();

  useEffect(() => {
    setTimeout(() => {
      !window.isGsiLoaded && SocialLoginInterface.loadGsi();
    }, 1500);
  }, []);

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={themes[ENVIRONMENT_VARIABLES.APPLICATION_TARGET]}>
        <NotificationProvider>
          {isMobile ? (
            <MobileApp />
          ) : (
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
              <ErrorBoundary>
                <AppInit>
                  <App />
                  <OnboardingLayer />
                  <MissionsTutorialLayer />
                  <LoadingLayer />
                </AppInit>
              </ErrorBoundary>
            </PersistGate>
          )}
        </NotificationProvider>
      </ThemeContext.Provider>
    </Provider>
  );
};

// dispatch events if the browser goes offline/comes back online
window.addEventListener('offline', state => {
  store.dispatch(setIsConnected(false));
});

window.addEventListener('online', state => {
  store.dispatch(setIsConnected(true));
});
// end dispatch events

async function detectAdBlock() {
  let adBlockEnabled = false;
  const googleAdUrl = ENVIRONMENT_VARIABLES.GOOGLE_AD_URL;
  try {
    await fetch(new Request(googleAdUrl)).catch(() => (adBlockEnabled = true));
  } catch (e) {
    adBlockEnabled = true;
  } finally {
    store.dispatch(setAdBlocker(adBlockEnabled));
  }
}

export default Redux;

